import React from 'react'

const Footer = () => {
    
    return (
        <footer>
            <p>2022 &#169; Made by <a id="meLink" href="https://github.com/ivanurra">Iván Urra</a></p>
        </footer>
    )
}

export default Footer
